@import url('https://fonts.googleapis.com/css2?family=Patua+One&family=Poppins:wght@200;300;400;500&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

* {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Patua One', cursive;
}

h1 {
  @apply text-[30px] md:text-[40px] xl:text-[60px] leading-[40px] md:leading-[50px] xl:leading-[70px]
}

h2 {
  @apply text-[28px] md:text-[38px] xl:text-[48px] leading-[38px] md:leading-[48px] xl:leading-[58px]
}

h3 {
  @apply text-[20px] md:text-[30px] xl:text-[40px] leading-[30px] md:leading-[40px] xl:leading-[50px]
}

h4 {
  @apply text-[20px] md:text-[27px] xl:text-[32px] leading-[22px] md:leading-[32px] xl:leading-[42px]
}

h5 {
  @apply text-[24px] leading-[34px]
}

h6 {
  @apply text-[16px] leading-[30px]
}

p {
  @apply text-xs sm:text-sm md:text-base
}

a {
  @apply whitespace-nowrap text-sm md:text-base
}

input {
  @apply text-troo-stateSecondary
}

.activeNav{
  @apply text-troo-primary after:absolute after:top-0 after:content-[''] after:w-[2px] after:h-9 after:bg-troo-primary
}

.activeMobileNav {
  @apply text-troo-primary
}

.main-container {
  @apply max-w-[1920px] mx-auto px-[20px] md:px-[50px] xl:px-[100px] 2xl:px-[150px];
}

.main-container-big {
  @apply max-w-[1920px] mx-auto px-[20px] md:px-[50px] xl:px-[100px] 2xl:px-[280px];
}

.main-container-big-r-0 {
  @apply max-w-[1920px] mx-auto pr-[20px] md:pr-[50px] xl:pr-[100px] 2xl:pr-[280px];
}

.btn-theme-primary {
  @apply relative border border-troo-primary text-troo-primary px-[30px] py-[12px] md:px-[40px] md:py-[14px] 2xl:px-[50px] xl:py-[16px] hover:text-troo-stateTertiary whitespace-nowrap text-xs sm:text-sm xl:text-base transition ease-in-out delay-150
}

.btn-theme-primary span {
  @apply text-troo-stateTertiary z-20
}

.btn-theme-primary div {
  @apply absolute bottom-1 right-1 w-[10px] h-[10px] bg-troo-primary z-10
}

.btn-theme-primary:hover div {
  @apply w-full h-full bottom-0 right-0 animate-smoothWidthHeight
}

.btn-theme-white {
  @apply relative bg-troo-stateTertiary text-troo-secondary px-[30px] py-[12px] md:px-[40px] md:py-[14px] xl:px-[50px] xl:py-[16px] hover:text-troo-stateTertiary whitespace-nowrap text-xs sm:text-sm xl:text-base transition ease-in-out delay-150
}

.btn-theme-white span {
  @apply text-troo-secondary z-20
}

.btn-theme-white div {
  @apply absolute bottom-1 right-1 w-[10px] h-[10px] bg-troo-secondary z-10
}

.btn-theme-white:hover div {
  @apply w-full h-full bottom-0 right-0 animate-smoothWidthHeight
}

.btn-theme-white:hover span {
  @apply text-troo-stateTertiary
}

.btn-theme-secondary {
  @apply relative bg-transparent text-troo-secondary border border-troo-secondary px-[30px] py-[12px] md:px-[40px] md:py-[14px] xl:px-[50px] xl:py-[16px] hover:text-troo-stateTertiary whitespace-nowrap text-xs sm:text-sm xl:text-base transition ease-in-out delay-150
}

.btn-theme-secondary span {
  @apply text-troo-secondary z-20
}

.btn-theme-secondary div {
  @apply absolute bottom-1 right-1 w-[10px] h-[10px] bg-troo-secondary z-10
}

.btn-theme-secondary:hover div {
  @apply w-full h-full bottom-0 right-0 animate-smoothWidthHeight
}

.btn-theme-secondary:hover span {
  @apply text-troo-stateTertiary
}

.service-card:hover .service-img {
  filter:  brightness(0.8)saturate(74)contrast(31.2)sepia(107)hue-rotate(224deg);
  -webkit-filter:  brightness(0.8)saturate(74)contrast(31.2)sepia(107)hue-rotate(224deg);
}

.service-card:hover .service-link, .service-card:hover .service-text{
  @apply text-troo-stateSecondary
}

.accordian-main{
  @apply bg-troo-secondary text-troo-stateTertiary
}

option {
  @apply text-troo-stateSecondary
}

.tab-theme-active {
  @apply py-2 px-6 bg-troo-primary rounded-full text-sm md:text-base cursor-pointer
}

.tab-theme {
  @apply py-2 px-6 border border-troo-stateTertiary text-troo-stateTertiary rounded-full hover:border-troo-primary hover:bg-troo-primary transition-colors text-sm md:text-base hover:text-troo-secondary cursor-pointer
}

.accordian-input:active .accordian-main {
  background-color: #EBB977 !important;
}

.swiper-slide {
  height: auto !important;
  align-self: stretch !important;
}

.swiper-pagination {
  position: relative !important;
  margin-top: 20px !important;
}

.slider-dot-left > .swiper-pagination {
  text-align: left !important;
}

.swiper-pagination-bullet {
  width: 18px!important;
  height: 2px!important;
  background: #fff !important;
}

.swiper-pagination-bullet-active {
  background: #EBB977 !important;
}

.swiper-slide:not(.swiper-slide-active) {
  opacity: 0.5;
}

.swiper:not(.first-active) .swiper-slide{
  opacity: 1;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

@media (min-width: 1024px) {
  .swiper {
    padding-top: 120px !important;
  }
}

.height-0,
:where(.collapse > input[type="radio"]) {
  min-height: 0;
}

 .swiper:not(.slider-overflow-visible), .swiper-container:not(.slider-overflow-visible) {
  padding-top: 0px !important;
}

